.wrapper {
  width: 460px;
}
.logo {
  width: 125px;
  height: 14px;
  margin-bottom: 8px;
}

.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 8px;
}

.warningRed {
  color: #fe5b37;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  gap: 6px;
  margin-bottom: 24px;
}

.warningRed img {
  width: 16px;
  height: 16px;
}

.info {
  background-color: #eff1f9;
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.item:not(:last-child) {
  border-bottom: 1px solid #dbe0f2;
  padding-bottom: 12px;
}

.item:not(:first-child) {
  padding-top: 12px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item img {
  width: 16px;
  height: 16px;
}

.item div span {
  color: #8e95b0;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}

.item div p {
  font-weight: 500;
  font-size: 16px;
  color: #2c2e35;
  margin-top: 4px;
}

.timer {
  color: #1890ff !important;
}

.notFilledItem {
  margin-bottom: 8px;
}

.notFilledItem span {
  font-weight: 400;
  color: #8e95b0;
  font-size: 14px;
  margin-bottom: 4px;
}

.notFilledItem p {
  font-weight: 500;
  color: #8e95b0;
  font-size: 16px;
}

.warning {
  color: #ffa216;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.warning img {
  width: 20px;
  height: 20px;
  animation: rotation 5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.emailBlock {
  font-weight: 400;
  font-size: 16px;
  color: #2c2e35;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.emailLink {
  font-weight: 400;
  font-size: 16px;
  color: #1890ff;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: center;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}