@import "../../../assets/styles/_fonts";

$huge_lg: 1920px;
$huge_sm: 1810px;
$desk_lg: 1628px;
$desk_sm: 1140px;
$tab_lg: 1024px;
$tab_md: 832px;
$tab_sm: 640px;
$mob_lg: 480px;
$mob_md: 390px;
$mob_m: 375px;
$mob_sm: 360px;

$backgrounds_b: rgb(18, 18, 21);
$backgrounds_a: rgb(0, 0, 0);
$text_text: rgb(255, 255, 255);
$text_description_a: rgba(255, 255, 255, 0.6);
$gold: #f4d483;
$brown_shadow: #9a6823;
$colors_system_success: #20ca4f;
$backgrounds_c: rgb(29, 29, 32);
$roundings_s: 8px;
$roundings_circle: 100px;
$colors_system_warning: #f90;
$buttons_primary_default: rgb(255, 255, 255);
$buttons_primary_text: $backgrounds_a;
$buttons_primary_disable: rgb(56, 56, 58);
$buttons_secondary_default: rgba(255, 255, 255, 0.6);
$buttons_secondary_text_default: rgb(255, 255, 255);
$buttons_secondary_hover: rgb(244, 212, 131);
$buttons_secondary_disable: rgba(255, 255, 255, 0.4);
$buttons_secondary_text_disable: rgba(255, 255, 255, 0.6);
$borders_b: rgba(255, 255, 255, 0.2);
$colors_system_error: rgb(255, 0, 61);

$universal_spacings_3xs: 2px;
$universal_spacings_2xs: 4px;
$universal_spacings_xs: 6px;
$universal_spacings_s: 8px;
$universal_spacings_m: 12px;
$universal_spacings_l: 16px;
$universal_spacings_xl: 20px;
$universal_spacings_2xl: 24px;
$universal_spacings_3xl: 32px;
$universal_spacings_4xl: 40px;
$universal_spacings_5xl: 58px;
$universal_spacings_6xl: 64px;

@mixin text_overflow_ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin input_field() {
  width: 100%;
  height: 44px;
  border-radius: 100px;
  border: 1px solid $borders-b;
  background-color: transparent;

  @include Text-Medium(400);
  @include transition();
}

@mixin transition($prop: all, $time: 0.2s, $duration: ease-out) {
  transition: $prop $time $duration;
}

@mixin Text_ExtraSmall($weight, $uppercase: false) {
  font-size: 12px;
  font-weight: $weight;
  line-height: 20px;

  @if $uppercase {
    text-transform: uppercase;
  }
}

@mixin hideScrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin tab_only {
  @media (min-width: $tab_sm) and (max-width: $desk_sm - 1px) {
    @content;
  }
}

@mixin mob_only {
  @media (max-width: $tab_sm - 1px) {
    @content;
  }
}

@mixin tab {
  @media (min-width: $tab_sm) {
    @content;
  }
}

@mixin Text_ExtraLarge($weight, $uppercase: false) {
  font-size: 20px;
  font-weight: $weight;
  line-height: 28px;

  @if $uppercase {
    text-transform: uppercase;
  }
}

@mixin Text_Small($weight, $uppercase: false) {
  font-size: 14px;
  font-weight: $weight;
  line-height: 20px;

  @if $uppercase {
    text-transform: uppercase;
  }
}

@mixin Text_Medium($weight, $uppercase: false) {
  font-size: 16px;
  font-weight: $weight;
  line-height: 24px;

  @if $uppercase {
    text-transform: uppercase;
  }
}

.payment_page {
  position: relative;
  min-height: stretch;
  min-width: 320px;
  color: $text_text;
  font-family: "Open Sans", "Helvetica Neue", system-ui, -apple-system, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  padding: 39px 0;
  gap: 49px;
  background: url(../../../assets/img/lex-bg-desk-min.png) center/cover no-repeat $backgrounds_b;

  & * {
    box-sizing: border-box;
  }
  @include hideScrollbar();

  @include tab_only {
    padding: 43px 0;

    background: url(../../../assets/img/lex-bg-tab-min.png) center/cover no-repeat $backgrounds_b;
  }

  @include mob_only {
    padding: 0;
    background: $backgrounds_b;
  }
}

.payment_page__logo {
  display: none;

  @include tab_only {
    display: block;
  }
}

.payment_dialog {
  width: 460px;
  max-width: 100%;
  padding: $universal_spacings_xl 28px 36px;
  background: $backgrounds_b;
  border-radius: 12px;

  @include mob_only {
    border-radius: 0;
    padding: $universal_spacings_xl $universal_spacings_l;
    width: 100%;
    height: 100%;
  }
}

.payment_dialog__header {
  display: flex;
  flex-direction: column;
  gap: $universal_spacings_s 0;
}

.payment_dialog__header_title {
  display: flex;
  align-items: center;
  gap: 0 $universal_spacings_s;

  @include Text_ExtraLarge(700);
}

.payment_dialog__header_id {
  display: flex;
  align-items: center;
  gap: 0 $universal_spacings_xs;
}

.payment_dialog__header_id_text {
  color: $text_description_a;

  @include Text_Small(400);
}

.payment_dialog__header_id_number {
  color: $gold;

  @include Text_Small(700);
}

.payment_dialog__body {
  padding-top: $universal_spacings_xl;
  display: flex;
  flex-direction: column;
  gap: $universal_spacings_2xl 0;
}

.payment_dialog__card {
  padding: $universal_spacings_m;
  border-radius: 8px;
  background: linear-gradient(80deg, rgba(244, 212, 131, 0.6) 15.47%, rgba(244, 212, 131, 0.13) 84.53%);
}

.payment_dialog__card_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $universal_spacings_m;
}

.payment_dialog__card_name {
  display: flex;
  align-items: center;
  gap: 0 $universal_spacings_s;

  @include Text_Small(700);
}

.payment_dialog__card_time {
  @include Text_ExtraLarge(700);

  @include mob_only {
    @include Text_Medium(700);
  }
}

.payment_dialog__card_balance {
  @include Text_ExtraLarge(700);

  @include mob_only {
    @include Text_Medium(700);
  }
}

.payment_dialog__card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment_dialog__card_commission {
  color: $text_description_a;

  @include Text_Small(400);
}

.payment_dialog__card_status {
  display: flex;
  align-items: center;
  gap: 0 $universal_spacings_2xs;
  color: $colors_system_success;

  @include Text_Small(600);
}

.payment_dialog__platform {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include tab {
    & > .payment_dialog__platform_text {
      display: none;
    }
  }
}

.payment_dialog__platform_wr {
  display: flex;
  gap: 0 16px;

  @include mob_only {
    .payment_dialog__platform_text {
      display: none;
    }
  }
}

.payment_dialog__platform_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment_dialog__platform_image_wr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $backgrounds_c;
  border-radius: 8px;
  width: 100px;
  height: 64px;
  flex-shrink: 0;
  padding: 10px 5px;
}

.payment_dialog__platform_image {
  max-width: 100%;
  max-height: 100%;
}

.payment_dialog__platform_title {
  margin-bottom: $universal_spacings_2xs;

  @include Text_Medium(700);
}

.payment_dialog__platform_text {
  color: $text_description_a;

  @include Text_Small(400);
}

.attention_block {
  position: relative;
  padding: $universal_spacings_s 0 $universal_spacings_s $universal_spacings_xl;
  border-radius: $roundings_s;
  background-color: $backgrounds_b;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: 0 14px 14px 0;
    background-color: $colors_system_warning;
  }
}

.attention_block__text {
  color: $text_description_a;

  @include Text_Small(400);
}

.attention_block__wr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
}

.attention_block__link {
  display: block;
  color: $gold;
  text-decoration: underline;
  margin-top: $universal_spacings_2xs;

  @include Text_Medium(400);
}

.btn {
  position: relative;
  background-color: $buttons_primary_default;
  color: $buttons_primary_text;
  border: none;
  padding: $universal_spacings_m $universal_spacings_2xl;
  height: 48px;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: $roundings_circle;
  justify-content: center;
  text-decoration: none;

  @include Text_Medium(600, true);
  @include transition();

  @include mob_only {
    height: 36px;
    padding: $universal_spacings_s $universal_spacings_xl;

    @include Text_ExtraSmall(600, true);
  }

  &.disabled,
  &[disabled] {
    background-color: $buttons_primary_disable;
    pointer-events: none;
  }
}

.btn__inner {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
}

.btn_secondary {
  border: 1px solid $buttons_secondary_default;
  background-color: transparent;
  font-weight: 600;
  color: $buttons_secondary_text_default;

  .no_touch & {
    &:hover {
      border: 1px solid $buttons_secondary_hover;
    }
  }

  &:active {
    border: 1px solid $buttons_secondary_hover;
  }

  &.disabled,
  &[disabled] {
    background-color: transparent;
    border: 1px solid $buttons_secondary_disable;
    color: $buttons_secondary_text_disable;
  }
}

.payment_dialog__form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.payment_dialog__form_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.input_field__wr {
  position: relative;
}

.input_field_label {
  margin-bottom: $universal_spacings_2xs;

  @include Text_ExtraSmall(700);
}

.input_field__input {
  padding: 0 16px;
  color: $text_text;

  @include input_field();

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
    -webkit-text-fill-color: $text_text !important;
    caret-color: $text_text !important;
    background-clip: text !important;
  }

  &[disabled] {
    border-color: $borders_b;
    color: $text_text;
    opacity: 1;

    @include text_overflow_ellipsis();
  }
}

.input_field__error {
  border-color: $colors_system_error !important;
}

.input_field__copy {
  display: flex;
  align-items: center;
  gap: $universal_spacings_s;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  text-decoration: underline;

  @include Text_Small(400);
}

.status_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status_block__title {
  text-align: center;
  margin-bottom: $universal_spacings_s;

  @include Text_ExtraLarge(700);
}

.status_block__description {
  text-align: center;
  color: $text_description_a;

  a {
    color: $gold;
    text-decoration: underline;
  }

  @include Text_Medium(400);
}
